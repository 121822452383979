import React, { FC, memo } from "react";
import {
  FlexCenteredVertically,
  GradienText,
} from "../styles/styled-component";
import { Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import linkedin from "../assets/linkedin.svg";
import { StyledButton } from "./header/styled";
import photo from "../assets/photo.png";
import { useIsMobile } from "../utils/use-is-mobile";
import { forMobile } from "../styles/adaptivity";
import { Container } from "../feature";

export const StyledLink = styled(Link)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 53.128,
  height: 53.128,
  border: "0.99px solid #575757",
  background: "rgba(255, 255, 255, 0.04)",
  borderRadius: "50%",
}));

export const InfoWrapper = styled(FlexCenteredVertically)(() => ({
  marginTop: 103.54,
  gap: 35.03,
  maxWidth: 596,
  borderRadius: 9.34,
  background: "rgba(255, 255, 255, 0.04)",
  padding: 31.88,

  [forMobile]: {
    flexDirection: "column",
    maxWidth: "100%",
    marginTop: 48,
    textAlign: "center",
  },
}));

export const Divider = styled("div")(() => ({
  background: "#959595",
  width: 1.328,
  height: 126,
}));

export const Image = styled("img")(() => ({
  maxWidth: 820,

  [forMobile]: {
    maxWidth: "47%",
  },
}));

export const General: FC = memo(() => {
  const isMobile = useIsMobile();

  return (
    <Container>
      <FlexCenteredVertically
        marginTop="39.85px"
        sx={{ width: "100%", justifyContent: "space-between" }}
      >
        <div>
          <Typography
            fontSize={isMobile ? 16 : 25}
            fontWeight={300}
            lineHeight="normal"
            letterSpacing={0.75}
          >
            CEO at Gamma Prime, Hedge Fund Manager
          </Typography>

          <FlexCenteredVertically
            gap="24px"
            marginTop={isMobile ? "16px" : "0px"}
          >
            {isMobile && <Image src={photo} alt="joshua" />}
            <GradienText
              variant="h1"
              gradient="linear-gradient(90deg, #555 0.34%, #FFF 42.06%, #C8C8C8 90.34%)"
              fontSize={isMobile ? 48 : 81.727}
              fontWeight={900}
              lineHeight="normal"
              letterSpacing={isMobile ? 1.44 : 2.452}
              marginTop={isMobile ? "0px" : "28px"}
            >
              Evan Szu
            </GradienText>
          </FlexCenteredVertically>

          <FlexCenteredVertically gap="32.35px" marginTop="38.53px">
            <StyledLink
              to="https://www.linkedin.com/in/evanszu"
              target="_blank"
            >
              <img src={linkedin} alt="linkedin" />
            </StyledLink>
          </FlexCenteredVertically>

          <FlexCenteredVertically
            gap={isMobile ? "24px" : "28.02px"}
            marginTop={isMobile ? "40px" : "71.22px"}
            flexDirection={isMobile ? "column" : "row"}
          >
            <StyledButton href="mailto:contact@evanszu.com" target="_blank">
              <GradienText
                gradient="linear-gradient(91deg, #909090 0.48%, #000 99.59%)"
                fontSize={23.35}
                fontWeight={700}
                lineHeight="normal"
                letterSpacing={0.701}
              >
                Work with me
              </GradienText>
            </StyledButton>

            <StyledButton styled="secondary" href="mailto:contact@evanszu.com">
              <GradienText
                gradient="linear-gradient(91deg, #656565 0.73%, #FFF 55.35%, #C7C7C7 100.04%)"
                fontSize={23.35}
                fontWeight={700}
                lineHeight="normal"
                letterSpacing={0.701}
              >
                Contact me
              </GradienText>
            </StyledButton>
          </FlexCenteredVertically>

          <InfoWrapper>
            <div>
              <GradienText
                gradient="linear-gradient(90deg, #636363 0%, #FFF 49.5%, #C7C7C7 100%)"
                fontSize={28.021}
                fontWeight={800}
                lineHeight="normal"
                letterSpacing={0.841}
              >
                3+
              </GradienText>

              <Typography
                fontSize={16.5}
                fontWeight={700}
                lineHeight="normal"
                letterSpacing={1.415}
                color="#DFDFDF"
                marginTop={"14.01px"}
              >
                Web3 Projects Innovated
              </Typography>
            </div>
            {!isMobile && <Divider />}

            <div>
              <GradienText
                gradient="linear-gradient(90deg, #636363 0%, #FFF 49.5%, #C7C7C7 100%)"
                fontSize={28.021}
                fontWeight={800}
                lineHeight="normal"
                letterSpacing={0.841}
              >
                17+
              </GradienText>

              <Typography
                fontSize={16.5}
                fontWeight={700}
                lineHeight="normal"
                letterSpacing={1.415}
                color="#DFDFDF"
                marginTop={"14.01px"}
              >
                Companies Advised
              </Typography>
            </div>
            {!isMobile && <Divider />}

            <div>
              <GradienText
                gradient="linear-gradient(90deg, #636363 0%, #FFF 49.5%, #C7C7C7 100%)"
                fontSize={28.021}
                fontWeight={800}
                lineHeight="normal"
                letterSpacing={0.841}
              >
                30+
              </GradienText>

              <Typography
                fontSize={16.5}
                fontWeight={700}
                lineHeight="normal"
                letterSpacing={1.415}
                color="#DFDFDF"
                marginTop={"14.01px"}
              >
                Years of Trading Experience
              </Typography>
            </div>
          </InfoWrapper>
        </div>

        {!isMobile && (
          <div>
            <Image src={photo} alt="joshua" />
          </div>
        )}
      </FlexCenteredVertically>
    </Container>
  );
});
