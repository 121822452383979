import React, { FC, memo } from "react";

interface IProps {
  height: number;
  style?: any;
}

export const Line: FC<IProps> = memo(({ height, style }) => {
  return (
    <svg
      style={style}
      width="33"
      height={height}
      viewBox="0 0 33 193"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_34_552)">
        <rect
          x="17"
          y="173"
          width="1.00002"
          height={height}
          transform="rotate(-180 17 173)"
          stroke="url(#paint0_linear_34_552)"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_34_552"
          x="0.5"
          y="0.5"
          width="32"
          height="192"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.211765 0 0 0 0 0.933333 0 0 0 0 0.847059 0 0 0 0.69 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_34_552"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_34_552"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_34_552"
          x1="17.03"
          y1="330.454"
          x2="17.03"
          y2="168.278"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.180065" stop-color="#39CAB9" stop-opacity="0" />
          <stop offset="1" stop-color="#39CAB9" />
          <stop offset="1.0001" stop-color="#39CAB9" />
        </linearGradient>
      </defs>
    </svg>
  );
});
