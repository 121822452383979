import { FC, memo, useCallback } from "react";
import { Header } from "../components/header";
import { Divider, General } from "../components/general";
import {
  DecorLine,
  FlexCenteredVertically,
  GradienText,
  Wrapper,
} from "../styles/styled-component";
import { Typography, styled } from "@mui/material";
import { Headline } from "../components/headline";
import { publicationsData, storyData } from "../utils/data";
import { Footer } from "../components/footer";
import bigGroup from "../assets/Group-big.png";
import { useIsMobile } from "../utils/use-is-mobile";
import { forMobile } from "../styles/adaptivity";
import { Line } from "../assets/line";
import careerImage from "../assets/career-image.png";
import blockFills from "../assets/blockFills.png";
import cole from "../assets/cole.png";
import cooper from "../assets/cooper.png";
import hiddenRoad from "../assets/hiddenRoad.png";
import talos from "../assets/talos.png";
import sendIcon from "../assets/send.svg";
import publicationsImage from "../assets/Publications-image.png";
import { useInView } from "react-intersection-observer";
import { LineScroll } from "../components/lanimated-line";
import stanford from "../assets/stanford.png";

const Group = styled("div")(() => ({
  position: "absolute",
}));

export const Container = styled(FlexCenteredVertically)(() => ({
  flexDirection: "column",
  maxWidth: 1522,
  width: "100%",
  margin: "0 auto",
  padding: "0 16px",

  [forMobile]: {
    overflow: "hidden",
  },
}));

const Partners = styled("div")(() => ({
  marginTop: 45,
  background:
    "linear-gradient(90deg, rgba(205, 205, 205, 0.85) 0%, rgba(255, 255, 255, 0.85) 51.83%, rgba(143, 143, 143, 0.85) 100%)",
  padding: "14px 0",

  [forMobile]: {
    background: "transparent",
  },
}));

const Contact = styled("div")(() => ({
  maxWidth: 652,
  margin: "179px auto 225px",

  "& form": {
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: 39.85,
    marginTop: 66.41,
  },

  [forMobile]: {
    padding: "0 16px",
  },
}));

const Input = styled("input")(() => ({
  display: "block",
  borderRadius: 10.626,
  background: "rgba(255, 255, 255, 0.04)",
  fontSize: 21.251,
  fontWeight: 500,
  letterSpacing: 0.638,
  lineHeight: "160%",
  color: "#FEFEFE",
  padding: "18.59px 31.88px",
  outline: "none",
  border: "none",
  width: "100%",
  "&:placeholder": {
    color: "#959595",
  },
}));

const TextArea = styled("textarea")(() => ({
  display: "block",
  borderRadius: 10.626,
  background: "rgba(255, 255, 255, 0.04)",
  fontSize: 21.251,
  fontWeight: 500,
  letterSpacing: 0.638,
  lineHeight: "160%",
  color: "#FEFEFE",
  padding: "18.59px 31.88px",
  outline: "none",
  border: "none",
  resize: "none",
  "&:placeholder": {
    color: "#959595",
  },
}));

const FormButton = styled("button")(() => ({
  borderRadius: 10.626,
  border: "2.656px solid #959595",
  width: "100%",
  padding: 15.94,
  background: "transparent",

  "&:disabled": {
    opacity: 0.4,
    cursor: "not-allowed",
  },
}));

const CareerImage = styled("img")(() => ({
  margin: "72px 0 131px",
  width: "100%",
  [forMobile]: {
    margin: "50px 0",
  },
}));

const PublicationsWrapper = styled("div")(() => ({
  display: "grid",
  gap: 264,
  [forMobile]: {
    gap: 24,
  },
}));

const PublicationsImage = styled("img")(() => ({
  marginTop: -131,
  width: "100%",
  [forMobile]: {
    marginTop: 0,
  },
}));

const Link = styled("a")(() => ({
  textDecoration: "none",
}));

const LineWrap = styled("div")<{ isVisible: boolean }>(({ isVisible }) => ({
  position: "absolute",
  left: "-4%",
  bottom: 24,
  opacity: isVisible ? 1 : 0,
  transform: isVisible ? "translateY(0)" : "translateY(-70%)",
  transition: "all 0.5s ease-in-out",

  [forMobile]: {
    position: "relative",
    margin: "0 auto",
    left: 0,
    bottom: 0,
  },
}));

export const InfoWrapper = styled(FlexCenteredVertically)(() => ({
  position: "absolute",
  bottom: "15%",
  right: 0,
  gap: 30,
  maxWidth: 810,

  [forMobile]: {
    position: "relative",
    flexDirection: "column",
    maxWidth: "100%",
    marginTop: 48,
    textAlign: "center",
  },
}));

export const LineScrollAnimation: FC<{ delay?: number; height?: number }> = ({
  delay = 0,
  height,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <LineWrap isVisible={inView} ref={ref}>
      <LineScroll height={height} />
    </LineWrap>
  );
};

export const MainPage: FC = memo(() => {
  const isMobile = useIsMobile();

  const handleSubmit = useCallback((event: any) => {
    event.preventDefault();
    window.open("mailto:contact@evanszu.com");
  }, []);

  return (
    <>
      <Header />
      <Wrapper>
        <General />
      </Wrapper>

      <>
        <Container
          id="about-me"
          sx={{
            marginTop: isMobile ? "85px" : "108px",
            position: "relative",
          }}
        >
          {!isMobile && (
            <Group sx={{ right: "-77%", top: "-46%" }}>
              <img src={bigGroup} alt="group" />
            </Group>
          )}

          {!isMobile && (
            <Group sx={{ left: "-89%", bottom: "-114%" }}>
              <img src={bigGroup} alt="group" />
            </Group>
          )}

          <Headline
            title="About Me"
            text="Interdisciplinary innovation in Finance, AI, Web3, and Science"
          />

          <Typography
            fontSize={isMobile ? 20 : 26.564}
            fontWeight={500}
            color="#959595"
            lineHeight="190%"
            marginTop="53px"
          >
            The intersection of disciplines is a challenging place to innovate,
            requiring expertise in not just one but multiple fields. Yet the
            rewards of working at such intersections, I’ve found to be well
            worthwhile. This multi-disciplinary thread is the hallmark of my
            professional career, combining fields as diverse as AI, derivatives
            trading, Web3, and biotechnology.
            <br /> <br />
            My latest venture, Gamma Prime, reaches across the fields of
            derivatives, Web3, and AI to tokenize the yield of financial
            products. This will open the flood gates to authentic yield,
            previously only available to ultra-wealthy hedge fund clients and
            gigantic institutional endowments.
            <br /> <br />
            Accomplishing this has required an unprecedented multi-disciplinary
            team of veteran Web3 innovators, expert machine learning developers,
            professional Chicago derivatives traders, and elite providers of
            hedge fund infrastructure. Gamma Prime is truly a tribute to the
            power of interdisciplinary innovation
          </Typography>
        </Container>

        <Container sx={{ position: "relative", mt: "119px" }} id="publications">
          <Headline
            title="Publications"
            text="Stanford University | SU · Graduate School of Education PhD"
          />
        </Container>

        <div style={{ position: "relative" }}>
          <PublicationsImage src={publicationsImage} alt="PublicationsImage" />
          <InfoWrapper>
            <div>
              <GradienText
                gradient="linear-gradient(90deg, #636363 0%, #FFF 49.5%, #C7C7C7 100%)"
                fontSize={28.021}
                fontWeight={800}
                lineHeight="normal"
                letterSpacing={0.841}
              >
                10
              </GradienText>

              <Typography
                fontSize={16.5}
                fontWeight={700}
                lineHeight="normal"
                letterSpacing={1.415}
                color="#DFDFDF"
                marginTop={"14.01px"}
              >
                Publications
              </Typography>
            </div>
            {!isMobile && <Divider />}

            <div>
              <GradienText
                gradient="linear-gradient(90deg, #636363 0%, #FFF 49.5%, #C7C7C7 100%)"
                fontSize={28.021}
                fontWeight={800}
                lineHeight="normal"
                letterSpacing={0.841}
              >
                10,000+
              </GradienText>

              <Typography
                fontSize={16.5}
                fontWeight={700}
                lineHeight="normal"
                letterSpacing={1.415}
                color="#DFDFDF"
                marginTop={"14.01px"}
              >
                Reads
              </Typography>
            </div>
            {!isMobile && <Divider />}

            <div>
              <GradienText
                gradient="linear-gradient(90deg, #636363 0%, #FFF 49.5%, #C7C7C7 100%)"
                fontSize={28.021}
                fontWeight={800}
                lineHeight="normal"
                letterSpacing={0.841}
              >
                592
              </GradienText>

              <Typography
                fontSize={16.5}
                fontWeight={700}
                lineHeight="normal"
                letterSpacing={1.415}
                color="#DFDFDF"
                marginTop={"14.01px"}
              >
                Citations
              </Typography>
            </div>
            <img src={stanford} alt="stanford" />
          </InfoWrapper>
        </div>

        <Container>
          <PublicationsWrapper>
            {publicationsData.map((d, idx) => (
              <Link target="_blank" href={d.link} key={d.text} rel="noreferrer">
                <FlexCenteredVertically
                  gap={isMobile ? "24px" : "10px"}
                  position="relative"
                  flexDirection={isMobile ? "column" : "row"}
                  alignItems={isMobile ? "start" : "center"}
                >
                  <LineScrollAnimation
                    height={
                      isMobile
                        ? 135
                        : idx === publicationsData.length - 1
                        ? 292
                        : 331
                    }
                  />
                  <Typography
                    maxWidth={1099}
                    fontSize={isMobile ? 24 : 30}
                    fontWeight={500}
                    lineHeight={isMobile ? "normal" : "190%"}
                    color="#DFDFDF"
                  >
                    {d.text}
                  </Typography>

                  <img src={sendIcon} alt="sendIcon" />
                </FlexCenteredVertically>
              </Link>
            ))}
          </PublicationsWrapper>
        </Container>

        <Container sx={{ position: "relative", mt: "217px" }} id="career">
          <Headline
            title="Career"
            text="Corporate leader with a strong track record of innovation and growth."
          />
        </Container>

        <CareerImage
          className="career-img"
          src={careerImage}
          alt="career img"
        />

        <Container>
          <FlexCenteredVertically
            flexDirection="column"
            gap={isMobile ? "32px" : "106px"}
            marginTop={isMobile ? "56px" : "106px"}
            width="100%"
            position="relative"
          >
            {storyData.map((s, idx) => (
              <div
                style={
                  idx % 2 !== 0
                    ? { marginLeft: "auto", position: "relative" }
                    : { marginRight: "auto", position: "relative" }
                }
              >
                {!isMobile && [0, 8].includes(idx) && (
                  <>
                    <DecorLine
                      sx={{ height: 441, right: "-45%", top: "-73%" }}
                      delay={3}
                    >
                      <Line
                        height={161}
                        style={{ position: "relative", top: "30%" }}
                      />
                    </DecorLine>

                    <DecorLine
                      sx={{ height: 441, left: "40%", top: "80%" }}
                      delay={3}
                    >
                      <Line
                        height={161}
                        style={{ position: "relative", top: "60%" }}
                      />
                    </DecorLine>

                    <DecorLine
                      sx={{ height: 441, right: "-60%", top: "260%" }}
                      delay={3}
                    >
                      <Line
                        height={161}
                        style={{ position: "relative", top: "60%" }}
                      />
                    </DecorLine>

                    <DecorLine
                      sx={{ height: 441, left: "50%", top: "430%" }}
                      delay={3}
                    >
                      <Line
                        height={161}
                        style={{ position: "relative", top: "60%" }}
                      />
                    </DecorLine>

                    <DecorLine
                      sx={{ height: 441, right: "-40%", top: "610%" }}
                      delay={3}
                    >
                      <Line
                        height={161}
                        style={{ position: "relative", top: "60%" }}
                      />
                    </DecorLine>

                    <DecorLine
                      sx={{ height: 441, left: "40%", top: "780%" }}
                      delay={3}
                    >
                      <Line
                        height={161}
                        style={{ position: "relative", top: "60%" }}
                      />
                    </DecorLine>
                  </>
                )}
                <Typography
                  fontSize={26.564}
                  fontWeight={500}
                  color="#959595"
                  lineHeight="190%"
                  letterSpacing={0.84}
                  textTransform="capitalize"
                  maxWidth={745}
                >
                  {s.text}
                </Typography>
              </div>
            ))}
          </FlexCenteredVertically>
        </Container>

        <Container
          sx={{ marginTop: isMobile ? "141px" : "173px" }}
          id="partners"
        >
          <Headline
            title="Partners"
            text="Evan Szu Acquired Leading Partners throughout His Career"
          />
        </Container>

        <Partners>
          <Container>
            <FlexCenteredVertically
              gap={isMobile ? "32px" : "60px"}
              flexDirection={isMobile ? "column" : "row"}
              justifyContent="center"
              flexWrap="wrap"
            >
              <img src={cooper} alt="cooper" />
              <img src={talos} alt="talos" />
              <img src={hiddenRoad} alt="hiddenRoad" />
              <img src={blockFills} alt="blockFills" />
              <img src={cole} alt="cole" />
            </FlexCenteredVertically>
          </Container>
        </Partners>

        <Contact sx={{ position: "relative" }} id="contact-me">
          <Group
            sx={{
              left: isMobile ? "-43%" : "-120%",
              bottom: isMobile ? "-50%" : "-67%",
              width: "100%",
              transform: "rotate(180deg)",
            }}
          >
            <img src={bigGroup} alt="group" />
          </Group>
          <Typography
            fontSize={53.128}
            fontWeight={700}
            letterSpacing={1.594}
            color="#FEFEFE"
            textAlign="center"
          >
            Contact me
          </Typography>

          <form>
            <Input type="text" required placeholder="Name" name="name" />
            <Input type="text" required placeholder="Email" name="email" />
            <Input type="text" placeholder="Phone Number" name="number" />
            <TextArea
              placeholder="Project Details..."
              rows={4}
              cols={50}
              name="message"
            />
            <FormButton type="submit" onClick={handleSubmit}>
              <GradienText
                gradient="linear-gradient(96deg, #5B5B5B -29.81%, #FFF 97.88%)"
                fontSize={26.564}
                fontWeight={700}
                letterSpacing={0.797}
                lineHeight="normal"
                textAlign="center"
              >
                Send
              </GradienText>
            </FormButton>
          </form>
        </Contact>

        <Footer />
      </>
    </>
  );
});
