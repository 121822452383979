import isEqual from "lodash/isEqual";
import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";

import { mobileWidth, tabletWidth } from "./endpoints";
import { useWindowSize } from "../../hooks/use-window-size";

interface IAdaptivityContext {
  deviceType: "desktop" | "tablet" | "mobile";
}

export const AdaptivityContext = createContext<IAdaptivityContext>({
  deviceType: "desktop",
});

export const AdaptivityProvider: FC<PropsWithChildren> = ({ children }) => {
  const [adaptivityState, setAdaptivityState] = useState<IAdaptivityContext>({
    deviceType: "desktop",
  });

  const { width: windowWidth, height: windowHeight } = useWindowSize();

  useEffect(() => {
    const deviceType =
      windowWidth <= mobileWidth
        ? "mobile"
        : windowWidth < tabletWidth
        ? "tablet"
        : "desktop";

    const nextState: IAdaptivityContext = {
      deviceType,
    };

    setAdaptivityState((prev) => (isEqual(prev, nextState) ? prev : nextState));
  }, [windowWidth, windowHeight]);

  return (
    <AdaptivityContext.Provider value={adaptivityState}>
      {children}
    </AdaptivityContext.Provider>
  );
};
