export const flexVertically = {
  display: 'flex',
  alignItems: 'center',
} as const;

export const flexHorizontallyColumn = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
} as const;

export const flexHorizontally = {
  display: 'flex',
  justifyContent: 'center',
} as const;

export const flexBoth = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
} as const;

export const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
} as const;

export const flexCenteredBetween = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
} as const;
