import { Typography } from "@mui/material";
import React, { FC, memo } from "react";
import { useIsMobile } from "../utils/use-is-mobile";

interface IProps {
  title: string;
  text: string;
}

export const Headline: React.FC<IProps> = memo(({ title, text }) => {
  const isMobile = useIsMobile();
  return (
    <>
      <Typography
        fontSize={isMobile ? 48 : 53.128}
        fontWeight={700}
        lineHeight="normal"
        letterSpacing={1.594}
        textAlign="center"
        color="#FEFEFE"
      >
        {title}
      </Typography>

      <Typography
        fontSize={isMobile ? 20 : 26.564}
        fontWeight={500}
        lineHeight="160%"
        letterSpacing={0.797}
        textAlign="center"
        marginTop={"24px"}
        textTransform="capitalize"
      >
        {text}
      </Typography>
    </>
  );
});
