import { styled } from "@mui/material";
import { FlexCenteredBetween } from "../../styles/styled-component";
import { forMobile } from "../../styles/adaptivity";

export const Container = styled(FlexCenteredBetween)(() => ({
    marginTop: 75,

    [forMobile]: {
        marginTop: 30,
    }
})) 

export const StyledButton = styled('a')<{styled?: 'secondary'}>(({styled}) => ({
    borderRadius: 10.626,
    background: styled === 'secondary' ? 'transparent' : 'linear-gradient(94deg, #FFF 3.1%, #B7B7B7 94.54%)',
    padding: '15.94px 53.13px',
    textTransform: 'initial',
    border: styled === 'secondary' ? '2.656px solid #959595' : 'none',
    textDecoration: 'none',
    display: 'block',
    textAlign: 'center',

    [forMobile]: {
        width: '100%',
        padding: 12,
    }
})) 

export const MobileWrapper = styled('div')(() => ({
    background: 'linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, 0) 100%)',
    backdropFilter: 'blur(24px)',
    position: 'fixed',
    width: '100%',
    height: '100vh',
    padding: '150px 16px 16px',
    top: 0,
    left: 0,
    zIndex: 1,
  }));
