


export const storyData = [
    { text: 'My interdisciplinary journey through AI, derivatives markets, and biochemistry has been a thrilling adventure, marked by continual evolution and engagement with new challenges.'},
    { text: 'I graduated cum lauda from Brown University with dual degrees in AI computer science and chemistry.  At the same time, I was making my first forays into markets, kicking off a 30 year career in derivatives trading.'},
    {text: 'Professionally, I began with AI modeling of debt markets at Capital One.  I then entered the cutting edge area of applying AI to derivatives at Maydone Systems.  In 2005, I then transitioned to Trnsfr, a boutique software development startup.'},
    { text: 'Returning to academia in 2008, I completed a doctorate at Stanford University, delving deep into the rich environment there of Silicon Valley innovators.  Many of these colleagues would become partners and advisors in my subsequent ventures.'},
    { text: 'Then in 2015 I engaged with the burgeoning world of Web3, co-founding a project to democratize AI data creation and curation.  Next, I co-founded Arrive PTE LTD, a pharma startup that leveraged the power of machine learning for drug discovery.'},
    { text: 'At the same time, my decades of trading derivatives had matured, with my actual 10 year returns substantially exceeding the S&P500 (78% greater annualized return) yet with dramatically reduced risk (84% less drawdown in negative years).'},
    { text: "And now, I've founded Gamma Prime to democratize the massive yield potential of crypto-structured products.  Gamma Prime has brought together an extraordinary team of Web3 innovators, machine learning experts, professional Chicago derivatives traders, and elite providers of hedge fund infrastructure:  truly a tribute to the power of interdisciplinary innovation."},
]


export const publicationsData = [
  {link: `https://www.researchgate.net/publication/304370077_Factual_accuracy_and_the_cultural_context_of_science_in_popular_media_Perspectives_of_media_makers_middle_school_students_and_university_students_on_an_entertainment_television_program?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ`, text: '“Factual accuracy and the cultural context of science in popular media: Perspectives of media makers, middle school students, and university students on an entertainment television program”'},
  {link: `https://www.researchgate.net/publication/299602661_The_development_and_validation_of_a_learning_progression_for_argumentation_in_science?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ`, text: '"The development and validation of a learning progression for argumentation in science"'},
  {link: `https://www.researchgate.net/publication/263775747_Ethnically_Diverse_Students'_Knowledge_Structures_in_First-Semester_Organic_Chemistry?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ`, text: `"Ethnically Diverse Students' Knowledge Structures in First-Semester Organic Chemistry"`},
  {link: 'https://www.researchgate.net/publication/311666541_A_New_Learning_Progression_for_Student_Argumentation_in_Scientific_Contexts?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ', text: '"A New Learning Progression for Student Argumentation in Scientific Contexts"'},
  {link: 'https://www.researchgate.net/profile/Evan-Szu', text: 'Access all Evan Szu’s publications'},
]