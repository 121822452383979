import { Typography, styled } from "@mui/material";
import {
  flexBoth,
  flexCenteredBetween,
  flexHorizontally,
  forMobile,
} from "./adaptivity";

interface IFlexBoxProps {
  className?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  margin?: string;
  marginRight?: string;
  marginLeft?: string;
  marginTop?: string;
  marginBottom?: string;
  transform?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
  hover?: Record<string, string>;
  flex?: string;
  flexWrap?: "wrap" | "nowrap";
  flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
  zIndex?: number;
  overflowX?: "scroll" | "hidden" | "visible";
  overflowY?: "scroll" | "hidden" | "visible";
  overflow?: "scroll" | "hidden" | "visible";
  background?: string;
  borderRadius?: string;
  gap?: string;
  minWidth?: string;
  minHeight?: string;
  position?: "static" | "relative" | "absolute" | "sticky" | "fixed";
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  border?: string;
  borderBottom?: string;
  borderTop?: string;
  alignSelf?: string;
  boxSizing?: "content-box" | "border-box";
  boxShadow?: string;
  float?: "left" | "right" | "none" | "inline-start" | "inline-end";
  opacity?: string;
  userSelect?: "auto" | "text" | "none" | "contain" | "all";
  backdropFilter?: string;
  flexShrink?: number;
}

export const FlexCenteredVertically = styled("div")<IFlexBoxProps>(
  ({
    height,
    width,
    maxWidth,
    maxHeight,
    margin,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    transform,
    alignItems,
    justifyContent,
    padding,
    hover,
    flex,
    flexWrap,
    flexDirection,
    zIndex,
    overflowX,
    overflowY,
    overflow,
    background,
    borderRadius,
    gap,
    minWidth,
    minHeight,
    position,
    top,
    bottom,
    left,
    right,
    border,
    borderBottom,
    borderTop,
    alignSelf,
    boxSizing,
    boxShadow,
    float,
    opacity,
    userSelect,
    backdropFilter,
    flexShrink,
  }) => ({
    display: "flex",
    height,
    width,
    maxWidth,
    maxHeight,
    margin,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    transform,
    alignItems: alignItems || "center",
    justifyContent,
    padding,
    flex,
    flexWrap,
    flexDirection,
    zIndex,
    overflowX,
    overflowY,
    overflow,
    background,
    borderRadius,
    gap,
    minWidth,
    minHeight,
    position,
    top,
    bottom,
    left,
    right,
    border,
    borderBottom,
    borderTop,
    alignSelf,
    boxSizing,
    boxShadow,
    float,
    opacity,
    userSelect,
    backdropFilter,
    flexShrink,
    "&:hover": hover,
  })
);

export const FlexCenteredBothAxises = styled("div")({
  ...flexBoth,
});

export const FlexCenteredBetween = styled("div")({
  ...flexCenteredBetween,
});

export const FlexCenteredHorizontally = styled("div")({
  ...flexHorizontally,
});

export const GradienText = styled(Typography)<{ gradient: string }>(
  ({ gradient }) => ({
    background: gradient,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  })
);

export const Wrapper = styled("div")(() => ({
  maxWidth: 1732,
  width: "100%",
  margin: "0 auto",
  padding: "0 16px",
}));

export const DecorLine = styled("div")<{ white?: boolean; delay?: number }>(
  ({ white, delay = 0 }) => ({
    position: "absolute",

    "&::after": {
      position: "absolute",
      content: '" "',
      opacity: white ? 0.2 : 0.3,
      background: white
        ? "linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)"
        : "linear-gradient(90deg, #FFF 0%, #08091D 50%)",
      width: 1,
      height: "100%",
      top: 0,
      left: "51%",
      transform: "translate(-50%, 0px)",
    },

    "@keyframes decorLine": {
      "0%": {
        transform: "translateY(-150%)",
        opacity: 0,
      },
      "5%": {
        opacity: 0.5,
      },
      "20%": {
        opacity: 1,
      },

      "80%": {
        opacity: 0.5,
      },
      "100%": {
        transform: "translateY(80%)",
        opacity: 0,
      },
    },

    "& svg": {
      animation: `decorLine 5s linear ${delay}s infinite`,
    },
  })
);
