import { FC, memo, useEffect, useState } from "react";
import { Container, MobileWrapper, StyledButton } from "./styled";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import {
  FlexCenteredVertically,
  GradienText,
  Wrapper,
} from "../../styles/styled-component";
import { Typography } from "@mui/material";
import { useIsMobile } from "../../utils/use-is-mobile";
import { useWindowSize } from "../../hooks/use-window-size";

export const links = [
  { link: "home", name: "Home" },
  { link: "about-me", name: "About me" },
  { link: "publications", name: "Publications" },
  { link: "career", name: "Career" },
  { link: "partners", name: "Partners" },
  { link: "contact-me", name: "Contact me" },
];

export const Header: FC = memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  const windowSize = useWindowSize();
  const isMenu = windowSize.width <= 1440;

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
    }
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, [isOpen]);

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleScroll = (event: any, id: string) => {
    closeMenu();
    event.preventDefault();

    const section = document.getElementById(id);
    if (section) {
      setTimeout(() => section.scrollIntoView({ behavior: "smooth" }), 0);
    }
  };

  return (
    <Wrapper id="home">
      <Container>
        <Link to="/" style={{ zIndex: 3 }}>
          <img src={logo} alt="logo" />
        </Link>

        {isMenu && (
          <div
            className={isOpen ? "burger burger-active" : "burger"}
            style={{ zIndex: 3 }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="strip burger-strip-2">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}

        {!isMenu && (
          <FlexCenteredVertically
            gap={isMobile ? "24px" : "55px"}
            flexDirection={isMobile ? "column" : "row"}
          >
            {links.map((l) => (
              <Typography
                fontSize={18}
                fontWeight={500}
                letterSpacing={0.69}
                lineHeight="normal"
                color="#959595"
                sx={{ cursor: "pointer" }}
                onClick={(e) => handleScroll(e, l.link)}
              >
                {l.name}
              </Typography>
            ))}
          </FlexCenteredVertically>
        )}

        {!isMenu && (
          <StyledButton href="mailto:contact@evanszu.com" target="_blank">
            <GradienText
              gradient="linear-gradient(90deg, #9F9F9F 0%, #000 100%)"
              fontSize={21.251}
              fontWeight={700}
              letterSpacing={"0.638px"}
              lineHeight="normal"
            >
              Work with me
            </GradienText>
          </StyledButton>
        )}

        {isMenu && isOpen && (
          <MobileWrapper>
            <FlexCenteredVertically
              gap={isMobile ? "24px" : "55px"}
              flexDirection={isMobile ? "column" : "row"}
              justifyContent={!isMobile ? "center" : "left"}
              marginTop={!isMobile ? "100px" : "0px"}
            >
              {links.map((l) => (
                <Typography
                  fontSize={23}
                  fontWeight={500}
                  letterSpacing={0.69}
                  lineHeight="normal"
                  color="#959595"
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => handleScroll(e, l.link)}
                >
                  {l.name}
                </Typography>
              ))}
            </FlexCenteredVertically>

            <StyledButton
              sx={{
                margin: !isMobile ? "100px auto 0" : "25px auto",
                maxWidth: !isMobile ? "300px" : "auto",
              }}
              href="mailto:contact@evanszu.com"
              target="_blank"
            >
              <GradienText
                gradient="linear-gradient(90deg, #9F9F9F 0%, #000 100%)"
                fontSize={21.251}
                fontWeight={700}
                letterSpacing={"0.638px"}
                lineHeight="normal"
              >
                Work with me
              </GradienText>
            </StyledButton>
          </MobileWrapper>
        )}
      </Container>
    </Wrapper>
  );
});
