import React, { FC, memo } from "react";

interface IProps {
  height?: number;
  style?: any;
}

export const LineScroll: FC<IProps> = memo(({ height, style }) => {
  return (
    <svg
      width="19"
      height={height}
      viewBox="0 0 19 341"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="9.5"
        y1="-6.55671e-08"
        x2="9.50001"
        y2="331"
        stroke="url(#paint0_linear_2007_121)"
        stroke-width="3"
      />
      <g filter="url(#filter0_f_2007_121)">
        <circle cx="9.5" cy="331.5" r="4.5" fill="#BCBCBC" />
      </g>
      <g filter="url(#filter1_f_2007_121)">
        <circle cx="9.5" cy="331.5" r="4.5" fill="#BCBCBC" />
      </g>
      <circle cx="9.5" cy="331.5" r="4.5" fill="#BCBCBC" />
      <defs>
        <filter
          id="filter0_f_2007_121"
          x="0"
          y="322"
          width="19"
          height="19"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2.5"
            result="effect1_foregroundBlur_2007_121"
          />
        </filter>
        <filter
          id="filter1_f_2007_121"
          x="0"
          y="322"
          width="19"
          height="19"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2.5"
            result="effect1_foregroundBlur_2007_121"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2007_121"
          x1="7.5"
          y1="2.18557e-08"
          x2="7.50001"
          y2="331"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BFBFBF" stop-opacity="0" />
          <stop offset="1" stop-color="#BFBFBF" />
        </linearGradient>
      </defs>
    </svg>
  );
});
