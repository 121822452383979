import { Typography, styled } from "@mui/material";
import { FC, memo } from "react";
import { FlexCenteredVertically } from "../styles/styled-component";
import { Link } from "react-router-dom";
import { StyledLink } from "./general";
import logo from "../assets/logo.png";
import linkedin from "../assets/linkedin.svg";
import mail from "../assets/mail.svg";
import { links } from "./header";
import { useIsMobile } from "../utils/use-is-mobile";

const FooterStyled = styled(FlexCenteredVertically)(() => ({
  background: "#000",
  padding: "53.13px 0 80.88px",
  flexDirection: "column",
  gap: 66.41,
  zIndex: 1,
  position: "relative",
}));

export const Footer: FC = memo(() => {
  const isMobile = useIsMobile();

  const handleScroll = (event: any, id: string) => {
    event.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <FooterStyled>
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>

      <FlexCenteredVertically
        gap={isMobile ? "24px" : "55px"}
        flexDirection={isMobile ? "column" : "row"}
      >
        {links.map((l) => (
          <Typography
            fontSize={isMobile ? 20 : 23}
            fontWeight={500}
            letterSpacing={0.69}
            lineHeight="normal"
            color="#959595"
            sx={{ cursor: "pointer" }}
            onClick={(e) => handleScroll(e, l.link)}
          >
            {l.name}
          </Typography>
        ))}
      </FlexCenteredVertically>

      <FlexCenteredVertically gap="25.56px">
        <StyledLink to="https://www.linkedin.com/in/evanszu" target="_blank">
          <img src={linkedin} alt="linkedin" />
        </StyledLink>
      </FlexCenteredVertically>

      <FlexCenteredVertically
        gap={isMobile ? "24px" : "55px"}
        flexDirection={isMobile ? "column" : "row"}
      >
        <Link
          style={{ textDecoration: "none" }}
          to="mailto:contact@evanszu.com"
          target="_blank"
        >
          <FlexCenteredVertically gap="18.59px">
            <img src={mail} alt="mail" />
            <Typography
              fontSize={isMobile ? 20 : 26.564}
              fontWeight={700}
              lineHeight="normal"
              letterSpacing={0.797}
              color="#959595"
            >
              contact@evanszu.com
            </Typography>
          </FlexCenteredVertically>
        </Link>
      </FlexCenteredVertically>
    </FooterStyled>
  );
});
